<template>
  <div class="row">
    <div class="col-sm-12 mt-2">
      <div class="card">
        <h2 class="text-center mt-2">Discount Code</h2>
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="row justify-content-center mb-2">
            <div class="col-sm-2">
              <div class="form-group form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="toggle"
                  v-model="toggleCheck"
                  @change="eventCheck"
                />
                <label class="form-check-label" for="toggle"
                  >Show All Code</label
                >
              </div>
            </div>
            <div class="col-sm-6">
              <b-form-input
                type="search"
                v-model="filter"
                placeholder="Type to search"
                @input="asyncFind"
                v-on:key.enter="asyncFind(filter)"
              >
              </b-form-input>
            </div>
            <router-link
              class="btn btn-primary mr-3"
              :to="{ path: 'discount-code/create' }"
            >
              <i class="fa fa-plus"></i>
              Add Discount Code
            </router-link>
          </div>

          <b-table
            class="mt-5"
            sticky-header="500px"
            :items="discount_code"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            show-empty
            hover
            responsive="xl"
          >
            <template #cell(No)="row">
              <div style="width: 30px">
                {{ (currentPage - 1) * perPage + (row.index + 1) }}.
              </div>
            </template>

            <template #cell(code)="row">
              <div style="width: 150px">
                <div @click="toggleShowCode(row.item.code)">
                  <div v-if="toggleCheck">
                    {{ row.item.code }}
                  </div>
                  <div v-else>
                    {{ hideCode(row.item.code) }}
                  </div>
                </div>
              </div>
            </template>

            <template #cell(discount)="row">
              <div style="width: 200px">
                <router-link
                  :to="{ path: '/discounts-detail/' + row.item.discounts.id }"
                  class="link"
                  target="_blank"
                >
                  {{ row.item.discounts.name }}
                </router-link>
              </div>
            </template>

            <template #cell(discount_idr)="row">
              <div style="width: 100px">
                {{
                  getDiscountIdr(
                    row.item.discounts.discount_idr,
                    row.item.discounts.discount_rule,
                  )
                }}
              </div>
            </template>

            <template #cell(discount_usd)="row">
              <div style="width: 100px">
                {{
                  getDiscountUsd(
                    row.item.discounts.discount_usd,
                    row.item.discounts.discount_rule,
                  )
                }}
              </div>
            </template>

            <template #cell(discountPrice)="row">
              <div style="width: 100px">
                {{
                  formatPrice(row.item.discount_price?.discount_price, 'IDR')
                }}
              </div>
            </template>

            <template #cell(expired)="row">
              <div style="width: 150px">
                {{ dateFormat(row.item.discounts.valid_to) }}
              </div>
            </template>

            <template #cell(new_user)="row">
              <div style="width: 100px;">
                <div v-if="row.item.is_for_new_user">
                  <span class="badge badge-pill badge-success">Yes</span>
                </div>
                <div v-else>
                  <span class="badge badge-pill badge-danger">No</span>
                </div>
              </div>
            </template>

            <template #cell(specified_user)="row">
              <div style="width: 150px; cursor: pointer">
                <div v-if="row.item.discountcode_user.user_id">
                  <span
                    class="badge badge-pill badge-success"
                    @click="getDataUser(row.item.discountcode_user.user_id)"
                    >click to view</span
                  >
                </div>
                <div v-else>
                  -
                </div>
              </div>
            </template>

            <template #cell(max_uses)="row">
              <div
                style="width: 100px"
                data-toggle="tooltip"
                data-placement="top"
                title="double click to update and then enter"
                @dblclick="changeEditable(row.item.id, row.index, 'change')"
                @keyup.enter="changeEditable(row.item.id, row.index, 'update')"
              >
                <div v-if="editable && formID == row.index">
                  <input
                    type="text"
                    :ref="row.index"
                    v-model="maxUse"
                    class="form-control form-control-sm"
                  />
                </div>
                <div v-else>
                  <i class="fa fa-pencil-square-o"></i> &nbsp;
                  {{ row.item.max_uses }}
                </div>
              </div>
            </template>

            <template #cell(current_uses)="row">
              <div style="width: 100px">
                {{ row.item.current_uses }}
              </div>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading...
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import constant from '../../store/constant';

export default {
  name: 'DiscountCode',
  data() {
    return {
      filter: null,
      currentPage: 1,
      perPage: 20,
      isLoadingSearch: false,
      debounce: null,
      fields: [
        { key: 'No', label: 'No.', thStyle: { maxWidth: '15px' } },
        { key: 'code', label: 'Discount Code' },
        { key: 'discount', label: 'Discount Name' },
        { key: 'discount_idr', label: 'Discount IDR' },
        { key: 'discount_usd', label: 'Discount USD' },
        { key: 'discountPrice', label: 'Discount Price' },
        { key: 'expired', label: 'Expired Date' },
        { key: 'new_user', label: 'New User' },
        {
          key: 'specified_user',
          label: 'Specified User',
        },
        { key: 'max_uses', label: 'Max Use' },
        { key: 'current_uses', label: 'Current Use' },
      ],
      toggleCheck: false,
      editable: false,
      formID: null,
      maxUse: null,
    };
  },
  watch: {
    currentPage: function() {
      this.getDiscountCode();
    },
    item: function() {
      this.$swal(this.item?.email);
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Discount Code | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.discount_code.isLoading,
      isError: (state) => state.discount_code.isError,
      totalRows: (state) => state.discount_code.totalRows,
      discount_code: (state) => state.discount_code.items,
      item: (state) => state.discount_code.item,
    }),
  },
  mounted() {
    this.getDiscountCode();
  },
  methods: {
    ...mapActions('discount_code', [
      'fetchDiscountCode',
      'findDiscountCode',
      'fetchDiscountCodeById',
      'fetchUserByid',
      'updateMaxUse',
    ]),
    ...mapActions('organization', [
      'fetchOrganization',
      'findOrganization',
      'fetchOrganizationById',
    ]),

    changeEditable(id, index, action) {
      this.editable = !this.editable;
      this.formID = index;
      if (action == 'update') {
        const payload = {
          maxUse: this.maxUse,
          id,
        };
        this.updateMaxUse(payload)
          .then((res) => {
            if (res?.status == 200) {
              this.$swal({
                icon: 'success',
                toast: true,
                position: 'top-end',
                title: 'maximal usage has been successfully updated',
                timer: 3000,
                text: this.message,
              });
            } else {
              if (res?.response.status == 422) {
                this.$swal({
                  icon: 'error',
                  toast: true,
                  position: 'top-end',
                  title: res?.response?.data?.message,
                  timer: 3000,
                  text: this.message,
                });
              }
            }
          })
          .catch(() => {
            this.$swal({
              icon: 'error',
              toast: true,
              position: 'top-end',
              title: 'Ops. Something went wrong',
              timer: 3000,
              text: this.message,
            });
          })
          .finally(() => {
            this.getDiscountCode();
            this.maxUse = null;
          });
      }
    },

    dateFormat(data) {
      return data != null ? moment(data).format('DD-MM-YYYY HH:mm:ss') : '-';
    },

    customLabel({ code }) {
      if (code) {
        return code;
      } else {
        return 'find discount code...';
      }
    },

    getDiscountCode() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      if (this.filter) {
        payload.q = this.filter;
      }
      this.fetchDiscountCode(payload);
    },

    asyncFind(query) {
      if (!query) {
        this.getDiscountCode();
        clearTimeout(this.debounce);
        return;
      }

      this.isLoadingSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          page: this.currentPage,
          limit: this.perPage,
        };

        this.fetchDiscountCode(payload);
      }, 600);
    },

    actionFindOne(query) {
      this.fetchDiscountCodeById(query);
    },

    hideCode(code) {
      return code.replace(/./g, '*');
    },

    toggleShowCode(code) {
      this.$swal(code);
    },

    eventCheck() {
      if (this.toggleCode) {
        this.toggleCode = false;
      } else {
        this.toggleCode = true;
      }
    },

    getDataUser(id) {
      this.fetchUserByid({ id });
    },

    formatPrice(value, currency) {
      if (value) {
        let formatter = null;
        if (currency == 'USD') {
          formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat('en-ID', {
            style: 'currency',
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return '0';
    },

    getDiscountIdr(amount, rule) {
      if (rule == constant.OFFER_DISCOUNT_RULE['Cut by Percentage.']) {
        return `${parseFloat(amount)} %`;
      }
      return this.formatPrice(amount, 'IDR');
    },

    getDiscountUsd(amount, rule) {
      if (rule == constant.OFFER_DISCOUNT_RULE['Cut by Percentage.']) {
        return `${parseFloat(amount)} %`;
      }
      return this.formatPrice(amount, 'USD');
    },
  },
};
</script>
